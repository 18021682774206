import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import filterIconBlue from "../../assets/img/filterIconBlue.svg";
import leftArrow from "../../assets/img/leftArrow.svg";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import noDataIconSmall from "../../assets/img/noDataIconSmall.svg";
import searchIcon from "../../assets/img/searchIcon.svg";
import { ReloadOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddService,
  actionGetProjectsDetailsComponents,
  actionGetProjectsDetailsIssues,
  actionGetProjectsDetailsServicesEOL,
  actionGetProjectServiceProductData,
  actionGetSingleProductData,
} from "../../store/services/projectService";
import SortIcon from "../common/SortIcon";
import { removeProduct } from "../../store/slices/projectSlice";

const AddService = () => {
  const [focusedData, setfocusedData] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectProductOptions, setSelectProductOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [productId, setProductId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const [addServiceForm] = Form.useForm();
  const projectId = state.projectId;
  const projectName = state.projectName;
  const {
    getProjectServiceProductDataLoader,
    projectServiceProductData,
    getSingleProductDataLoader,
    singleProductData,
    addServiceLoader
  } = useSelector((state) => state.project);

  const customEmpty = (
    <div className="flex flex-col items-center">
      <img src={noDataIcon} alt="No Data" />
      <p className="text-[#393939] text-[15px] font-montserratMedium">
        No Data Found
      </p>
    </div>
  );

  const handleSearchProduct = (value, arr) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchValue.length >= 3) {
      dispatch(actionGetProjectServiceProductData(searchValue));
    }
  }, [searchValue, dispatch]);

  useEffect(() => {
    if (focusedData !== 1) {
      dispatch(actionGetProjectServiceProductData(''));
    }
  }, [focusedData,dispatch]);

  // Update select options when the API call is successful
  useEffect(() => {
    if (projectServiceProductData && projectServiceProductData.length > 0) {
      const options = projectServiceProductData.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
      }));
      setSelectProductOptions(options);
    } else if(projectServiceProductData && projectServiceProductData?.content.length > 0){
      const options = projectServiceProductData.content.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
      }));
      setSelectProductOptions(options);
    }else{
      setSelectProductOptions([]);
    }
  }, [projectServiceProductData]);

  const handleChangeServiceProduct = (value) => {
    if (value.length > 0) {
      const recentId = value[value.length - 1];
      if (recentId) {
        dispatch(actionGetSingleProductData(recentId));
        setProductId(recentId);
      }
    }
  };
  const handleDeselectServiceProduct = (id) => {
    dispatch(removeProduct(id));
     setSelectedRows((prevSelectedRows) => {
    const updatedSelectedRows = { ...prevSelectedRows };
    delete updatedSelectedRows[id]; // Remove the selected rows for the deselected product
    return updatedSelectedRows;
  });
  };

  const productColumns = [
    {
      title: "Release Version",
      dataIndex: "releaseVersion",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "released On",
      dataIndex: "releaseOn",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Active Support",
      dataIndex: "activeSupport",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    // {
    //   title: "Additional Info",
    //   dataIndex: "additionalInfo",
    //   ellipsis: true,
    //   render: (text) => <span className="ml-2">{text || "-"}</span>,
    // },
  ];

  const handleAddService = () => {
    const selectedData = Object.keys(selectedRows).map((productId) => ({
      productId,
      selectedSupportDetailsIds: selectedRows[productId] || [],
    }));

    dispatch(actionAddService({ id: projectId, values: selectedData }))
    .then(() => {
     
      navigate("/project-detail", {
        state: {
          projectId: projectId,
          projectName: projectName,
          tab: "3",
        },
      });
    })
    .catch((error) => {
      // Handle any error from the API call here if needed
      console.error("Error adding service:", error);
    });
  };

  const handleClear = () => {
    console.log("All selections cleared");
    setProductId(null);
  };

  // Handle row selection for each product
  const handleRowSelectionChange = (productId, selectedRowKeys) => {
    setSelectedRows((prev) => ({
      ...prev,
      [productId]: selectedRowKeys,
    }));
  };

  const isAnyRowSelected = () => {
    return Object.values(selectedRows).some((selectedRowKeys) => selectedRowKeys.length > 0);
  };

// console.log(projectId)

  return (
    <>
      <div className="flex justify-between items-center">
        <div
          className="cursor-pointer mr-2 flex gap-x-2"
          onClick={() => navigate(-1)}
        >
          <Image src={leftArrow} alt="image" preview={false} />
          <p className="text-text-primary text-[20px] font-montserratRegular">
            Back
          </p>
        </div>
        <h1 className="text-text-primary text-[22px] font-montserratMedium text-center mb-2">
          Add Service
        </h1>
      </div>
      <Card className="bg-transparent border border-border min-h-min">
        <h1 className=" text-text-primary text-[18px] font-montserratMedium mb-2">
          <span className="text-primary font-montserratMedium">
            {" "}
            Project Name:{" "}
          </span>{" "}
          {projectName || "-"}
        </h1>
        <Select
          className="w-full h-[40px] custom-select"
          showSearch
          placeholder="Search to Select"
          // popupClassName={searchValue.length < 3 ? "tag-select" : ""}
          onSearch={handleSearchProduct}
          optionFilterProp="label"
          mode="multiple"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase()) 
          }
          onChange={handleChangeServiceProduct}
          onFocus={()=> setfocusedData(focusedData+1)}
          onDeselect={handleDeselectServiceProduct}
          onClear={handleClear}
          options={selectProductOptions}
          loading={getProjectServiceProductDataLoader}
          notFoundContent={
            <div className="flex flex-col justify-center items-center py-3">
              <div>
                <img src={noDataIconSmall} alt="no data" />
              </div>
              <p className="text-text-primary font-montserratMedium">No Data</p>
            </div>
          }
        />

        <div className="mt-3 lg:mt-5">
          {singleProductData &&
            singleProductData.length > 0 &&
            singleProductData.map((product, index) => {
              const rowSelection = {
                selectedRowKeys: selectedRows[product.productId] || [],
                onChange: (selectedRowKeys) =>
                  handleRowSelectionChange(product.productId, selectedRowKeys),
              };

              return (
                <>
                  <div key={index} className="mb-4">
                    <div className="w-full h-[40px] bg-[#14365E] rounded flex items-center mb-2">
                      <p className="ml-3 text-white font-montserratMedium text-[16px]">
                        {product.productName}
                      </p>
                    </div>

                    {/* Dynamic Table for Each Product */}
                    <Table
                      rowSelection={rowSelection}
                      rowKey={(record) => record.productDetailsId}
                      pagination={false}
                      className="bg-transparent"
                      columns={productColumns}
                      dataSource={
                        Array.isArray(product.supportDetails)
                          ? product.supportDetails
                          : []
                      }
                      scroll={{ y: 300 }}
                      loading={getSingleProductDataLoader}
                      locale={{ emptyText: customEmpty }}
                    />
                  </div>
                </>
              );
            })}
          <div className="flex justify-end mt-[40px]">
            <Form.Item className="mb-0">
              <button
                onClick={() => {
                  navigate("/project-detail", {
                    state: {
                      projectId: projectId,
                      projectName: projectName,
                      tab: "3",
                    },
                  });
                }}
                type="button"
                className="rounded-[6px] text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[80px] text-[15px] font-montserratMedium"
              >
                Cancel
              </button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                disabled={!isAnyRowSelected()} // Disable if no rows are selected
                  loading={addServiceLoader}
                  onClick={handleAddService}
                  type="primary"
                  className=" min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                  htmlType="submit"
                >
                  Add Service
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AddService;
