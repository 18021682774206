export const getCreatedOn = (createdDate) => {
// Parse the date string into a Date object
const timestamp = new Date(createdDate);

// Define options for formatting the date to match "Mon 8th Jul 2024" style
const options = {
  weekday: 'short', // 'Mon'
  day: 'numeric',   // '8'
  month: 'short',   // 'Jul'
  year: 'numeric'   // '2024'
};

// Format the date
const formattedDate = timestamp.toLocaleDateString('en-GB', options);

// Function to add suffix (st, nd, rd, th) to the day
function getDayWithSuffix(day) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const relevantSuffix = (day % 10 <= 3 && (day < 11 || day > 13)) ? suffixes[day % 10] : suffixes[0];
  return day + relevantSuffix;
}

// Extract the day and apply suffix
const dayWithSuffix = getDayWithSuffix(timestamp.getDate());

// Final formatted output
const finalDate = `${formattedDate.replace(timestamp.getDate(), dayWithSuffix)}`;

return finalDate;
}
