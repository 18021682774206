import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddProject,
  actionAddService,
  actionGetDashboardAreaChartData,
  actionGetDashboardPieChartData,
  actionGetPortfolioVulnerabilitiesChartData,
  actionGetProjectsByOrgId,
  actionGetProjectsDetailsComponents,
  actionGetProjectsDetailsIssues,
  actionGetProjectsDetailsServicesEOL,
  actionGetProjectsEndOfLife,
  actionGetProjectServiceProductData,
  actionGetProjectsFindings,
  actionGetSingleProductData,
  actionGetAddProjectmodalfiletypes,
  actionGetProjectsDetailsMetrics,
  action_deleteProject,
} from "../services/projectService";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    addProjectLoader: false,
    getProjectsLoader: false,
    projects: [],
    projectsFindings: [],
    getProjectsfindingsLoader: false,
    projectsDetailsIssues: {},
    getProjectsDetailsIssuesLoader: false,
    projectsDetailsComponents: {},
    getProjectsDetailsComponentsLoader: false,
    projectsDetailsServicesEOL: [],
    getProjectsDetailsServicesEOLLoader: false,
    totalProjects: "",
    totalProjectsComponent: "",
    totalProjectsIssues: "",
    totalProjectsServicesEOL: "",
    projectsEndOfLife: [],
    getProjectsEndOfLifeLoader: false,
    getPortfolioVulnerabilitiesChartDataLoader: false,
    portfolioVulnerabilitiesChartData: "",
    getDashboardPieChartDataLoader: false,
    dashboardPieChartData: "",
    getProjectServiceProductDataLoader: false,
    projectServiceProductData: "",
    getSingleProductDataLoader: false,
    singleProductData: [],
    addServiceLoader:false,
    AddProjectmodalDisplayNamesData:[],
    AddProjectmodalDisplayNamesLoader:false,
    actionGetProjectsDetailsMetricsData:[],
    actionGetProjectsDetailsMetricsLoader:false,
    action_deleteProjectData:[],
    action_deleteProjectLoader:false,
    // downloadExcelfileData:'',
    // downloadExcelfileLoader:false
  },
  reducers: {
    removeProduct: (state, action) => {
      // console.log(
      //   state.singleProductData.map((product) => product.productId),
      //   action.payload
      // );

      state.singleProductData = state.singleProductData.filter(
        (product) => product.productId !== action.payload
      );
    },
    setProjectafterDelete: (state, action) => {
      let updatedProjects = state.projects.filter(
        (project) => project?.id !== action?.payload
      )
      if(updatedProjects) state.projects = [...updatedProjects];
    },
  },
  extraReducers: (buider) => {
    buider

      // actionAddProject state
      .addCase(actionAddProject.pending, (state) => {
        state.addProjectLoader = true;
      })
      .addCase(actionAddProject.fulfilled, (state, action) => {
        state.addProjectLoader = false;
        // state.projects.push(action.payload);
      })
      .addCase(actionAddProject.rejected, (state) => {
        state.addProjectLoader = false;
      })

      // actionGetProjectsByOrgId State
      .addCase(actionGetProjectsByOrgId.pending, (state) => {
        state.getProjectsLoader = true;
      })
      .addCase(actionGetProjectsByOrgId.fulfilled, (state, action) => {
        state.projects = action.payload?.projects;
        state.totalProjects = action.payload?.totalItems;
        state.getProjectsLoader = false;
      })
      .addCase(actionGetProjectsByOrgId.rejected, (state) => {
        state.getProjectsLoader = false;
      })

      // actionGetProjectsFindings State
      .addCase(actionGetProjectsFindings.pending, (state) => {
        state.getProjectsfindingsLoader = true;
      })
      .addCase(actionGetProjectsFindings.fulfilled, (state, action) => {
        state.projectsFindings = action.payload;
        state.getProjectsfindingsLoader = false;
      })
      .addCase(actionGetProjectsFindings.rejected, (state) => {
        state.getProjectsfindingsLoader = false;
      })

      // actionGetProjectsDetailsComponents State
      .addCase(actionGetProjectsDetailsComponents.pending, (state) => {
        state.getProjectsDetailsComponentsLoader = true;
      })
      .addCase(
        actionGetProjectsDetailsComponents.fulfilled,
        (state, action) => {
          state.projectsDetailsComponents = action.payload;
          state.totalProjectsComponent = action.payload?.totalItems;
          state.getProjectsDetailsComponentsLoader = false;
        }
      )
      .addCase(actionGetProjectsDetailsComponents.rejected, (state) => {
        state.getProjectsDetailsComponentsLoader = false;
      })

       // actionGetProjectsDetailsMetrics State
       .addCase(actionGetProjectsDetailsMetrics.pending, (state) => {
        state.actionGetProjectsDetailsMetricsLoader = true;
      })
      .addCase(
        actionGetProjectsDetailsMetrics.fulfilled,
        (state, action) => {
          state.actionGetProjectsDetailsMetricsData = action.payload;
          state.actionGetProjectsDetailsMetricsLoader = false;
        }
      )
      .addCase(actionGetProjectsDetailsMetrics.rejected, (state) => {
        state.actionGetProjectsDetailsMetricsLoader = false;
      })

      //  // actionGetProjectsDetailsMetrics State
      //  .addCase(downloadExcelfile.pending, (state) => {
      //   state.downloadExcelfileLoader = true;
      // })
      // .addCase(
      //   downloadExcelfile.fulfilled,
      //   (state, action) => {
      //     state.downloadExcelfileData = action.payload;
      //     state.downloadExcelfileLoader = false;
      //   }
      // )
      // .addCase(downloadExcelfile.rejected, (state) => {
      //   state.downloadExcelfileLoader = false;
      // })

      // actionGetProjectsDetailsIssues State
      .addCase(actionGetProjectsDetailsIssues.pending, (state) => {
        state.getProjectsDetailsIssuesLoader = true;
      })
      .addCase(actionGetProjectsDetailsIssues.fulfilled, (state, action) => {
        state.projectsDetailsIssues = action.payload;
        state.totalProjectsIssues = action.payload?.totalItems;
        state.getProjectsDetailsIssuesLoader = false;
      })
      .addCase(actionGetProjectsDetailsIssues.rejected, (state) => {
        state.getProjectsDetailsIssuesLoader = false;
      })

      // actionGetProjectsDetailsServicesEOL State
      .addCase(actionGetProjectsDetailsServicesEOL.pending, (state) => {
        state.getProjectsDetailsServicesEOLLoader = true;
      })
      .addCase(
        actionGetProjectsDetailsServicesEOL.fulfilled,
        (state, action) => {
          state.projectsDetailsServicesEOL = action.payload;
          state.totalProjectsServicesEOL = action.payload?.totalItems;
          state.getProjectsDetailsServicesEOLLoader = false;
        }
      )
      .addCase(actionGetProjectsDetailsServicesEOL.rejected, (state) => {
        state.getProjectsDetailsServicesEOLLoader = false;
      })

      // actionGetProjectsEndOfLife State
      .addCase(actionGetProjectsEndOfLife.pending, (state) => {
        state.getProjectsEndOfLifeLoader = true;
      })
      .addCase(actionGetProjectsEndOfLife.fulfilled, (state, action) => {
        state.projectsEndOfLife = action.payload;
        state.getProjectsEndOfLifeLoader = false;
      })
      .addCase(actionGetProjectsEndOfLife.rejected, (state) => {
        state.getProjectsEndOfLifeLoader = false;
      })

      // actionGetPortfolioVulnerabilitiesChartData State
      .addCase(actionGetPortfolioVulnerabilitiesChartData.pending, (state) => {
        state.getPortfolioVulnerabilitiesChartDataLoader = true;
      })
      .addCase(
        actionGetPortfolioVulnerabilitiesChartData.fulfilled,
        (state, action) => {
          state.portfolioVulnerabilitiesChartData = action.payload;
          state.getPortfolioVulnerabilitiesChartDataLoader = false;
        }
      )
      .addCase(actionGetPortfolioVulnerabilitiesChartData.rejected, (state) => {
        state.getPortfolioVulnerabilitiesChartDataLoader = false;
      })

      // actionGetDashboardPieChartData State
      .addCase(actionGetDashboardPieChartData.pending, (state) => {
        state.getDashboardPieChartDataLoader = true;
      })
      .addCase(actionGetDashboardPieChartData.fulfilled, (state, action) => {
        state.dashboardPieChartData = action.payload;
        state.getDashboardPieChartDataLoader = false;
      })
      .addCase(actionGetDashboardPieChartData.rejected, (state) => {
        state.getDashboardPieChartDataLoader = false;
      })

      // actionGetProjectServiceProductData State
      .addCase(actionGetProjectServiceProductData.pending, (state) => {
        state.getProjectServiceProductDataLoader = true;
      })
      .addCase(
        actionGetProjectServiceProductData.fulfilled,
        (state, action) => {
          state.projectServiceProductData = action.payload;
          state.getProjectServiceProductDataLoader = false;
        }
      )
      .addCase(actionGetProjectServiceProductData.rejected, (state) => {
        state.getProjectServiceProductDataLoader = false;
      })

      // actionGetSingleProductData state
      .addCase(actionGetSingleProductData.pending, (state) => {
        state.getSingleProductDataLoader = false;
      })

      .addCase(actionGetSingleProductData.fulfilled, (state, action) => {
        if (
          !state.singleProductData ||
          !Array.isArray(state.singleProductData)
        ) {
          state.singleProductData = [action.payload];
        } else {
          const productExists = state.singleProductData.some(
            (product) => product.productId === action.payload.productId
          );

          if (!productExists) {
            state.singleProductData.push(action.payload);
          }
        }
        state.getSingleProductDataLoader = false;
      })

      .addCase(actionGetSingleProductData.rejected, (state) => {
        state.getSingleProductDataLoader = false;
      })

      // actionAddService state
      .addCase(actionAddService.pending, (state) => {
        state.addServiceLoader = true;
      })
      .addCase(actionAddService.fulfilled, (state, action) => {
        state.singleProductData = [];
        state.addServiceLoader = false;
      })
      .addCase(actionAddService.rejected, (state) => {
        state.addServiceLoader = false;
      })

      // action_deleteProject State
      .addCase(action_deleteProject.pending, (state) => {
        state.action_deleteProjectLoader = true;
      })
      .addCase(action_deleteProject.fulfilled, (state, action) => {
        state.action_deleteProjectData = action.payload;
        state.action_deleteProjectLoader = false;
      })
      .addCase(action_deleteProject.rejected, (state) => {
        state.action_deleteProjectLoader = false;
      })

      // actionGetAddProjectmodalfiletypes state
      .addCase(actionGetAddProjectmodalfiletypes.pending, (state) => {
        state.AddProjectmodalDisplayNamesLoader = true;
      })
      .addCase(actionGetAddProjectmodalfiletypes.fulfilled, (state, action) => {
        if(action?.payload && Object.keys(action?.payload).length > 0){
          let modifiedData = Object.keys(action?.payload).map((key,index) => ({
            label: key,
            value: key
          }));
          
          state.AddProjectmodalDisplayNamesData = [...modifiedData];
        }else{
          state.AddProjectmodalDisplayNamesData = [];
        }
        state.AddProjectmodalDisplayNamesLoader = false;

      })
      .addCase(actionGetAddProjectmodalfiletypes.rejected, (state) => {
        state.AddProjectmodalDisplayNamesLoader = false;
      });
  },
});

export const { removeProduct,setProjectafterDelete } = projectSlice.actions;
export default projectSlice.reducer;
