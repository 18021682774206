export function isVersionGreater(version, latestVersion) {
    // Normalize versions by splitting into arrays of numbers/strings
    const parseVersion = (v) => v?.split(/[\.\-v]/)?.map((part) => (isNaN(part) ? part : Number(part)));
  
    const versionParts = parseVersion(version);
    const latestVersionParts = parseVersion(latestVersion);
  
    // Compare each part of the version
    for (let i = 0; i < Math.max(versionParts?.length, latestVersionParts?.length); i++) {
      const v1 = versionParts[i] || 0; // Use 0 if part is missing
      const v2 = latestVersionParts[i] || 0;
  
      if (v1 > v2) return false; // version is greater
      if (v1 < v2) return true; // latestVersion is greater
    }
  
    return false; // Versions are equal
  }
  
