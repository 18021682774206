import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleUnauthorized } from "./commonService";
import Swal from 'sweetalert2'
import { setProjectafterDelete } from "../slices/projectSlice";

export const actionAddProject = createAsyncThunk(
  "actionAddProject",
  async ({ req, handleCancel }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("projectVo", JSON.stringify(req.projectVo));

      if (req.sbomFile instanceof File) {
        formData.append("sbomFile", req.sbomFile);
      }
      
      formData.append("displayName", req.displayName);
      
      const response = await axios.post(
        `${BASE_URL}project/create/optionalsbom`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "*/*",
          },
        }
      );
      const { status, data } = response;
      if (status >= 200 && status < 300) {

        Swal.fire({
          title: 'Project Added Successfully!',
          text: 'We will notify you once the scanning is complete.',
          icon: 'success',
          allowOutsideClick: false, // Prevent closing when clicking outside
          confirmButtonText: 'Okay',
        });
        
        // toast.success("Project Added Successfully We will notify you once scanning is complete", { autoClose: 5000 });
        handleCancel && handleCancel();
        return data;
      } else {
        return rejectWithValue("reject");
      }
    } catch (error) {
      // const errorMessage =
      //   error.response?.data?.error || error.message || "An error occurred";
      // toast.error(errorMessage, 5);
      // toast.error("Project already exist", 5);
      toast.error(error?.message, 5);
    }
  }
);

// Action to get projects by org id
export const actionGetProjectsByOrgId = createAsyncThunk(
  "actionGetProjectsByOrgId",
  async ({ id, sort, currentPage, itemsPerPage }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/get-projects/${id}?page=${
          currentPage - 1
        }&size=${itemsPerPage}${sort ? `&sort=${sort}` : ""}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get project listing top card data
export const actionGetProjectsFindings = createAsyncThunk(
  "actionGetProjectsFindings",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/dashboard/sca-findings/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        toast.error("You are not authorized. Please log in again.", 5);
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get project detail component data
export const actionGetProjectsDetailsComponents = createAsyncThunk(
  "actionGetProjectsDetailsComponents",
  async ({
    id,
    currentPageForComponent,
    itemsPerPageForComponent,
    selectedSeverity,
    onlyOutdatedComponents,
    hasVulnerabilitiesComp,
      directdepenciesComp
  }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}project/get-project-details/${id}/components?page=${
          currentPageForComponent - 1
        }&size=${itemsPerPageForComponent}&hasVulnerabilities=${hasVulnerabilitiesComp}${
          selectedSeverity ? `&severity=${selectedSeverity}` : ""
        }&onlyDirectDependencies=${directdepenciesComp}&onlyOutdatedComponents=${onlyOutdatedComponents}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get project detail issues data
export const actionGetProjectsDetailsIssues = createAsyncThunk(
  "actionGetProjectsDetailsIssues",
  async ({
    id,
    currentPageForIssues,
    itemsPerPageForIssues,
    selectedSeverity,
  }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}project/get-project-details/${id}/issues?${
          selectedSeverity ? `&severity=${selectedSeverity}` : ""
        }&page=${currentPageForIssues - 1}&size=${itemsPerPageForIssues}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get project detail services end of life data
export const actionGetProjectsDetailsServicesEOL = createAsyncThunk(
  "actionGetProjectsDetailsServicesEOL",
  async ({ id, currentPageForServicesEOL, itemsPerPageForServicesEOL }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}project/get-project-details/${id}/eol?page=${
          currentPageForServicesEOL - 1
        }&size=${itemsPerPageForServicesEOL}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get dashboard bottom card data - end of life
export const actionGetProjectsEndOfLife = createAsyncThunk(
  "actionGetProjectsEndOfLife",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/dashboard/service-endoflife/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        // handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get dashboard area chart data
export const actionGetPortfolioVulnerabilitiesChartData = createAsyncThunk(
  "actionGetPortfolioVulnerabilitiesChartData",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/graph-vulnerabilities/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        // handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get dashboard pie chart data
export const actionGetDashboardPieChartData = createAsyncThunk(
  "actionGetDashboardPieChartData",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/metrics-piechart/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        // handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get service product data
export const actionGetProjectServiceProductData = createAsyncThunk(
  "actionGetProjectServiceProductData",
  async (name) => {
    try {
      const res = await axios.get(
        `${BASE_URL}search/products?name=${name}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        // handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);


// action to get service single product data
export const actionGetSingleProductData = createAsyncThunk(
  "actionGetSingleProductData",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}get/products-details/product/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 200) {
        return "";
      } else if (status === 200) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        // handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);


//Action add service EOL
export const actionAddService = createAsyncThunk(
  "actionAddService",
  async ({id, values }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}project/endOfLife/${id}`,
        values
      );
      const { status, data } = response;
      if (status === 201) {
        toast.success("Successfully Created", 5);
        handleCancel && handleCancel();
        return data;
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get project listing top card data
export const actionGetAddProjectmodalfiletypes = createAsyncThunk(
  "actionGetAddProjectmodalfiletypes",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}project/display-names`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        toast.error("You are not authorized. Please log in again.", 5);
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get project detail component data
export const actionGetProjectsDetailsMetrics = createAsyncThunk(
  "actionGetProjectsDetailsMetrics",
  async ({id}) => {
    try {
      const res = await axios.get(`${BASE_URL}project/get-project-details/${id}`);
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// action to get project detail component data
export const action_deleteProject = createAsyncThunk(
  "action_deleteProject",
  async ({id},{ dispatch }) => {
    try {
      const res = await axios.delete(`${BASE_URL}project/${id}`);
      const { data, status } = res;
      if (status === 200) {
        dispatch(setProjectafterDelete(id))
        Swal.fire(data, "", "success");
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);